import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; Umsobomvu Construction(Pty)Ltd </p>
      <p>Block D, 20 Grahamstown Rd, Comsec, Gqeberha, 6001</p>
    </div>
  );
};

export default Footer;